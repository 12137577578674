canvas {
  position: fixed;
}
body {
  height: 100vh;
  background: url(http://localhost/wp-content/themes/stradigi/images/bg/bg-1.jpg) no-repeat center center;
  background-size: cover;
}
.error-404 {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}
